import React, { Component } from "react";
import "./Mentions.css";

class MentionsLayout extends Component {
    render() {
        return (
            <div className="Mentions">
                <h2>1. Présentation du site :</h2>

                <p>
                    Conformément aux dispositions des articles 6-III et 19 de la Loi n°
                    2004-575 du 21 juin 2004 pour la Confiance dans l'économie
                    numérique, dite L.C.E.N., nous portons à la connaissance des
                    utilisateurs et visiteurs du site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    les informations suivantes :
                </p>
                <h2>Informations légales :</h2>

                <p>
                    Statut du propriétaire : <strong>societe</strong>
                    <br />
                    Préfixe : <strong>SAS</strong>
                    <br />
                    Nom de la Société :<strong> Sunchain</strong>
                    <br />
                    Adresse : <strong>105 Av. Alfred Kastler - BP 90434 - 66004 PERPIGNAN - France</strong>
                    <br />
                    Tél : <strong>+33468681658</strong>
                    <br />
                    Au Capital de :<strong> 215000 €</strong>
                    <br />
                    SIRET : <strong>82048842700015 </strong>
                    R.C.S. : <strong>820488427</strong>
                    <br />
                    Numéro TVA intracommunautaire : <strong>FR83820488427</strong>
                    <br />
                    Adresse de courrier électronique :{" "}
                    <a href="mailto:info@sunchain.fr">
                        <strong>info@sunchain.fr</strong>
                    </a>
                    <br />
                    <br />
                    Le Créateur du site est : <strong>Aurélien FAVRE-BERTIN</strong>
                    <br />
                    Le Responsable de la publication est : <strong>Julien Gil</strong>
                    <br />
                    Contactez le responsable de la publication :{" "}
                    <a href="mailto:info@sunchain.fr">
                        <strong>info@sunchain.fr</strong>
                    </a>
                    <br />
                    Le responsable de la publication est une{" "}
                    <strong>personne physique</strong>
                    <br />
                    <br />
                    Le Webmaster est : <strong>Aurélien FAVRE-BERTIN</strong>
                    <br />
                    Contactez le Webmaster :{" "}
                    <a href="mailto:info@sunchain.fr">
                        <strong>info@sunchain.fr</strong>
                    </a>
                    <br />
                    L’hebergeur du site est : OVH{" "}
                    <a href="https://www.ovh.com">
                        <strong>https://www.ovh.com</strong>
                    </a>
                    <br />
                </p>

                <h2>2. Description des services fournis :</h2>

                <p>
                    Le site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    a pour objet de fournir une information concernant l’ensemble des
                    activités de la société. Le propriétaire du site s’efforce de
                    fournir sur le site www.sunchain.fr des informations aussi précises
                    que possible. Toutefois, il ne pourra être tenue responsable des
                    omissions, des inexactitudes et des carences dans la mise à jour,
                    qu’elles soient de son fait ou du fait des tiers partenaires qui lui
                    fournissent ces informations. Toutes les informations proposées sur
                    le site www.sunchain.fr sont données à titre indicatif, sont non
                    exhaustives, et sont susceptibles d’évoluer. Elles sont données sous
                    réserve de modifications ayant été apportées depuis leur mise en
                    ligne.
                </p>
                <h2>3. Propriété intellectuelle et contrefaçons :</h2>

                <p>
                    Le propriétaire du site est propriétaire des droits de propriété
                    intellectuelle ou détient les droits d’usage sur tous les éléments
                    accessibles sur le site, notamment les textes, images, graphismes,
                    logo, icônes, sons, logiciels…
                </p>
                <p>
                    Toute reproduction, représentation, modification, publication,
                    adaptation totale ou partielle des éléments du site, quel que soit
                    le moyen ou le procédé utilisé, est interdite, sauf autorisation
                    écrite préalable. Adressez-vous à :{" "}
                    <a href="mailto:info@sunchain.fr">
                        <strong>info@sunchain.fr</strong>
                    </a>
                    .
                </p>
                <p>
                    Toute exploitation non autorisée du site ou de l’un quelconque de
                    ces éléments qu’il contient sera considérée comme constitutive d’une
                    contrefaçon et poursuivie conformément aux dispositions des articles
                    L.335-2 et suivants du Code de Propriété Intellectuelle.
                </p>

                <h2>4. Liens hypertextes et cookies :</h2>
                <p>
                    Le site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    contient un certain nombre de liens hypertextes vers d’autres sites
                    (partenaires, informations …) mis en place avec l’autorisation du
                    propriétaire du site. Cependant, le propriétaire du site n’a pas la
                    possibilité de vérifier le contenu des sites ainsi visités et
                    décline donc toute responsabilité de ce fait quand aux risques
                    éventuels de contenus illicites. Le site Sunchain n’utilise pas de
                    cookies.
                </p>
                <h2>
                    5. Protection des biens et des personnes - gestion des données
                    personnelles :
                </h2>
                <p>
                    Utilisateur : Internaute se connectant, utilisant le site susnommé :{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>
                    .
                </p>
                <p>
                    En France, les données personnelles sont notamment protégées par la
                    loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
                    l'article L. 226-13 du Code pénal et la Directive Européenne du 24
                    octobre 1995.
                </p>
                <p>
                    Sur le site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>
                    , le propriétaire du site ne collecte des informations personnelles
                    relatives à l'utilisateur que pour le besoin de certains services
                    proposés par le site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>
                    . L'utilisateur fournit ces informations en toute connaissance de
                    cause, notamment lorsqu'il procède par lui-même à leur saisie. Il
                    est alors précisé à l'utilisateur du site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    a l’obligation ou non de fournir ces informations.
                </p>
                <p>
                    Conformément aux dispositions des articles 38 et suivants de la loi
                    78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
                    aux libertés, tout utilisateur dispose d’un droit d’accès, de
                    rectification, de suppression et d’opposition aux données
                    personnelles le concernant. Pour l’exercer, adressez votre demande à{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    par email : email du webmaster ou en effectuant sa demande écrite et
                    signée, accompagnée d’une copie du titre d’identité avec signature
                    du titulaire de la pièce, en précisant l’adresse à laquelle la
                    réponse doit être envoyée.
                </p>
                <p>
                    Aucune information personnelle de l'utilisateur du site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée
                    ou vendue sur un support quelconque à des tiers. Seule l'hypothèse
                    du rachat du site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    au propriétaire du site et de ses droits permettrait la transmission
                    des dites informations à l'éventuel acquéreur qui serait à son tour
                    tenu de la même obligation de conservation et de modification des
                    données vis à vis de l'utilisateur du site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>
                    .
                </p>
                <p>
                    Le site{" "}
                    <a href="https://www.sunchain.fr">
                        <strong>www.sunchain.fr</strong>
                    </a>{" "}
                    est en conformité avec le RGPD puisqu’aucune donnée n’est
                    actuellement collectée. Simple. Basique.
                </p>
                <p>
                    Les bases de données sont protégées par les dispositions de la loi
                    du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
                    relative à la protection juridique des bases de données.
                </p>
            </div>
        );
    }
}

export default MentionsLayout;
