import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mentions from "../components/Mentions";
import logoNormal from '../media/svg/_LOGO_NORMAL.svg';
import logoNormalMobile from '../media/svg/_LOGO_MOB.svg';
import menuButton from '../media/svg/menu-button.svg';
import logo from '../media/svg/_LOGO_CONTOUR_BLANC.svg';
import town from '../media/img/cloud-solaire.jpg';
import roof from '../media/img/bg-techno.jpg';
import schema from '../media/img/smart-grid.jpg';

class HomeLayoutEn extends Component {

    render() {
        return (
            <div className="App">
                <div className="container-fluid">
                    <div className="row">

                        <nav className="navbar navbar-expand-lg justify-content-between">
                            <a href="#topSite" className="navbar-brand mr-0 mobile">
                                <img src={logoNormalMobile} alt="logo" className="logo1m logoNavM mr-3" />
                                <img src={logoNormal} alt="logo" className="logo1 logoNavD" />
                            </a>

                            <button className="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                <img src={menuButton} alt="logo" id="logoHambuger" />
                            </button>


                            <div className="navbar-collapse collapse justify-content-between align-items-center mr-xl-5" id="collapsingNavbar">
                                <ul className="navbar-nav mx-auto text-center">

                                    <li className="nav-item desktop mt-2 mt-lg-0">
                                        <a href="#topSite" className="navbar-brand pt-0 mr-5">
                                            <img src={logoNormalMobile} alt="logo" className="logo1m logoNavM" />
                                            <img src={logoNormal} alt="logo" className="logo1 logoNavD" />
                                        </a>
                                    </li>

                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link" href="#concept">CONCEPT</a>
                                    </li>
                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link " href="#autoconso">USE CASE</a>
                                    </li>
                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link " href="#techno">TECHNO</a>
                                    </li>
                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link " href="#applications">APPLICATIONS</a>
                                    </li>
                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link " href="#team">TEAM</a>
                                    </li>
                                    <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                        <a className="nav-link " href="#contact">CONTACT</a>
                                    </li>
                                </ul>
                                <ul>
                                    <li className="nav-item d-lg-inline-block">
                                        <a className="nav-link  btn btn-lg connect mt-3" href="https://app.sunchain.io">
                                            CONNEXION2{/* <i className="fa fa-user pl-2"></i> */}
                                        </a>
                                    </li>

                                    <li className="nav-item d-lg-inline-block mt-2 mt-lg-0 ml-3">
                                        <Link to="/">
                                            <span className="grey">FR</span> | EN
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </nav>

                    </div>
                </div>

                <header>
                    <div className="container-fluid">

                        <div className="row" id="topSite">
                            <div className="col-12">
                                <div className="page-header">
                                    <div className="">
                                        <img src={logo} alt="logo" id="logo2" />
                                        <div className="clear" />
                                        <span>
                                            Energy is our future,
                                            <br />
                                            <b>share it !</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 desktop mt-2 resize">
                                <div className="videoWrapper mt-5 pt-5">
                                    <iframe
                                        src="https://player.vimeo.com/video/207315547"
                                        width="560"
                                        height="349"
                                        frameBorder="0"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowFullScreen
                                        title="Video Sunchain"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6" id="concept">
                                <div className="half-contentR">
                                    <h2 className="pt-lg-3 pt-lg-2 bigSize">THE CONCEPT</h2>
                                    <h3 className=" pb-3 ml-5">Services supporting energy transition</h3>
                                    <div className="mobile video">
                                        <iframe
                                            src="https://player.vimeo.com/video/207315547"
                                            width="100%"
                                            height="100%"
                                            frameBorder="0"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            allowFullScreen
                                            title="Video Sunchain"
                                        />
                                    </div>
                                    <p>
                                        «  Digital technologies are set to transform the global energy system, making it more connected, reliable and sustainable.  »
                                    <br />
                                        <i>IEA (International Energy Agency)</i>
                                    </p>
                                    <p>
                                        <b>New patterns</b>  of energy production and consumption arise. The future grid will be decarbonized, decentralized and digitalized.
                                        <b> Local energy networks </b>  are to be particularly favored.
                                    </p>
                                    <p className="pb-5">
                                        By offering <b>project developers</b>  and <b>utilities </b>a blockchain-based solution to manage energy exchanges, Sunchain is leading this transformation.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-6" id="autoconso">
                                <div className="half-contentL">
                                    <h2 className="pt-lg-3">COLLECTIVE SELF-CONSUMPTION</h2>
                                    <h3 className=" pb-3 ml-5">Pool and share local solar energy</h3>
                                    <img className="mobile townM" src={town} alt="logo" />
                                    <p>
                                        Based on blockchain & IoT technologies, Sunchain’s solution manages the <b> energy exchanges </b> within local energy communities, gathering producers, consumers and prosumers.
                                    </p>
                                    <p>
                                        Power production and consumption data are <b>encrypted, signed</b>  and <b>recorded</b> in the blockchain directly from the smart meters. The energy allocation between all involved participants is<b> securely</b> processed and certified.
                                    </p>
                                    <p className="pb-5">
                                        Sunchain implements energy sharing, according to the <b> network operator </b>
                                        with the distribution results.
                                    </p>

                                </div>
                            </div>

                            <div className="col-lg-6 bgImage town desktop">
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-6 bgImage roof desktop">
                            </div>

                            <div className="col-lg-6" id="techno">
                                <div className="half-contentR">
                                    <h2 className="pt-3">A UNIQUE TECHNOLOGY</h2>
                                    <h3 className=" pb-3 ml-5">A blockchain specifically designed for energy sharing</h3>
                                    <img className="mobile roofM" src={roof} alt="logo" />
                                    <p>
                                        Energy management is performed by a blockchain application<b> tracking</b>,
                                        <b> securing</b> and <b>certifying</b> energy exchanges. Nodes are embedded on<b> IoT devices </b>
                                        that record data from smart meters into the blockchain.
                                    </p>
                                    <p>
                                        Sunchain’s vision of sustainable development is illustrated by the very nature of its <b>consortium blockchain</b>
                                        , using no mining process. This process benefits from a
                                        <b> very low electricity consumption</b>. This confiance <b>'tokenless'</b> blockchain, not linked to cryptocurrency, meets
                                        <b> trust</b> and <b>scalability</b> requirements.
                                    </p>
                                    <p className="pb-5">
                                        Sunchain’s blockchain architecture has been designed for the<b> renewable energy world</b>
                                        , and its applications in energy and smart grids are extensive.
                                    </p>
                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-lg-6" id="applications">
                                <div className="half-contentL">
                                    <h2 className="pt-3">APPLICATIONS</h2>
                                    <h3 className=" pb-3 ml-5">A blockchain system for smart grids management</h3>
                                    <img className="mobile townM" src={schema} alt="schema" />
                                    <p>
                                        Numerous applications can be deployed within<b> smart grids</b>
                                        . From the energy data recorded into the blockchain, smart contracts enable the performance of various actions on the  <b>network</b>
                                        , such as grid control, consumption management, storage control, etc.
                                    </p>
                                    <p>
                                        In the same way, Sunchain works on several projects linking solar energy to <b>electric mobility</b>
                                        . Sunchain’s blockchain will track and certify green charging for users and communities.
                                    </p>
                                    <p>
                                        Besides, Sunchain’s blockchain inherently guarantees the origin of energy. As amounts of energy are written in an immutable ledger, the solution offers services of
                                        <b> origin certification</b>  for renewable production and
                                        <b> traceability </b>
                                        for energy consumption.
                                    </p>
                                </div>

                                <div className="threeBlocks half-contentL d-flex justify-content: between">
                                    <div className="smart">
                                        <h5>SMART GRID</h5>
                                    </div>
                                    <div className="mobil">
                                        <h5>
                                            ELECTRIC
                                        <br />
                                            MOBILITY
                                    </h5>
                                    </div>
                                    <div className="certif pb-5">
                                        <h5>
                                            CERTIFICATE
                                        <br />
                                            OF ORIGIN
                                    </h5>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6 bgImage schema desktop">
                            </div>
                        </div>

                        <div className="row align-items-end" id="team">
                            <div className="col-lg-6">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-6 col-md-4 pro01">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    ANDRE JOFFRE <br />
                                                    <span>Founder / Advisor</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 pro02">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    CHRISTOPHE COURTOIS  <br />
                                                    <span>President / Co-founder</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 pro03">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    CAROLINE PLAZA  <br />
                                                    <span>CTO / Co-founder</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 pro04">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    JULIEN GIL  <br />
                                                    <span>Project engineer / Development manager</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 pro05">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    JULIEN CAPPIELLO  <br />
                                                    <span>CTO</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 pro06">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    HARLEY PAGES  <br />
                                                    <span>IoT engineer</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="bgImage half-contentRTeam ">
                                    <div>
                                        <h2 className="">THE TEAM</h2>
                                        <h3 className=" ml-5">Who are we ?</h3>
                                    </div>
                                    <p className="pt-3">
                                        A team of experts in solar and IT, <br />
                                        based in Perpignan.
                                    </p>
                                    <div className="">
                                        <p className="greentech">
                                            <b>
                                                Laureate
                                                <br />
                                                GREENTECH VERTE
                                            </b>
                                        </p>
                                        <p className="derbi">
                                            <b>
                                                Competitiveness cluster
                                                <br />
                                                DERBI
                                            </b>
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </main >

                <footer className="page-footer font-small mdb-color pt-4" id="contact">
                    <div className=" desktop">
                        <div className="container text-center text-md-left">
                            <div className="row text-center text-md-left mt-3 pb-3">

                                <div className="col-md-3 col-lg-2 offset-lg-2 mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">SUNCHAIN</h6>
                                    <div className=" mt-2">
                                        <a className="" href="#concept">Concept</a>
                                    </div>
                                    <div className=" mt-2">
                                        <a className="" href="#autoconso">Use case</a>
                                    </div>
                                    <div className=" mt-2">
                                        <a className="" href="#techno">Techno</a>
                                    </div>
                                    <div className=" mt-2">
                                        <a className="" href="#applications">Applications</a>
                                    </div>
                                    <div className=" mt-2 mb-4">
                                        <a className="" href="#team">Team</a>
                                    </div>
                                    <hr className="w-100 clearfix d-md-none" />
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold ml-3">Infos</h6>
                                    <div className=" mt-2">
                                        <a className="nav-link text-left" href="https://angel.co/company/sunchain">
                                            We're hiring<img src="https://s.w.org/images/core/emoji/11/svg/1f680.svg" alt="emoji" className="emoji" draggable="false" />
                                        </a>
                                    </div>
                                </div>
                                <hr className="w-100 clearfix d-md-none" />
                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Follow us</h6>
                                    <div>

                                        <a href="https://twitter.com/SunChain_fr" className="">
                                            <i className="fa fa-twitter"></i>
                                        </a>

                                        <a href="https://www.linkedin.com/company/sunchain">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </div>
                                </div>


                                <hr className="w-100 clearfix d-md-none" />

                                <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3 text-left">

                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                                    <p className="pt-0">
                                        <i className="fa fa-home"></i>105 avenue Alfred Kastler - BP51008<br />
                                        <span className="pad">66101 Perpignan Cedex - France</span></p>
                                    <p>
                                        <i className="fa fa-envelope"></i> <a href="mailto:info@sunchain.fr">info@sunchain.fr</a></p>
                                    <p>
                                        <i className="fa fa-phone"></i>+33 (0)4 68 68 16 58</p>

                                </div>

                                <hr />

                            </div>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pb-4">
                                        <a className="text-left" href="https://angel.co/company/sunchain">
                                            We're hiring<img src="https://s.w.org/images/core/emoji/11/svg/1f680.svg" alt="emoji" className="emoji" draggable="false" />
                                        </a>
                                    </div>
                                    <p>
                                        105 Av. Alfred Kastler - BP51008<br />
                                        66101 Perpignan - France<br /><br />
                                    </p>

                                    <a href="mailto:info@sunchain.fr">info@sunchain.fr</a>
                                    <br /><br />
                                    +33 (0)4 68 68 16 58
                                    <br /><br />

                                    <div>
                                        <span className="">Follow us : &nbsp;
                                            <a href="https://twitter.com/SunChain_fr">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </span>
                                        <a href="https://www.linkedin.com/company/sunchain">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                        <br /><br />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="container-fluid endDeskMobile">
                    <div>
                        <div className="infocopyright">
                            Copyright 2021&#160;&#160;&#160;|
                                    {/* Button trigger modal */}
                            <button type="button" className="btn" data-toggle="modal" data-target="#modalMobile">
                                Legal notices
                            </button>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="modalMobile" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title" id="exampleModalLongTitle">Legal notices</h1>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Mentions />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >

        )//return
    }

}

export default HomeLayoutEn;