import React, { Component } from 'react';

class Modal extends Component {
    render() {
        return (
            <div>
                <div className="modal fade" id={"fiche" + this.props.modalName} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content bodyFiche" >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <iframe src={this.props.pdf + '#toolbar=0'} width="100%" height="600px" title={"Fiche " + this.props.modalName}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )//return
    }
}

export default Modal;