import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBuilding, faCity,faUsers, faRocket  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fab, faBuilding, faCity, faUsers, faRocket)

class Example extends Component {
    render() {
        return (
            <div>
                <div className="card card-example">
                    <div className="card-body">
                        <h4 className="card-title-example">{this.props.title}</h4>
                        <FontAwesomeIcon icon={this.props.icon} size="3x" />
                        <p className="card-text pl-2 pr-2 card-text-example mt-3" >{this.props.text}</p>
                    </div>
                </div>
            </div >
        )//return
    }
}

export default Example;