import React, { Component } from 'react';
import town from '../../media/img/town.jpg';
import roof from '../../media/img/bg-techno.jpg';
import visa from '../../media/img/VISA.jpg';
// import ademe from '../../media/img/ADEME.png';
import bpifrance from '../../media/img/bpifrance.png';
import continental from '../../media/img/continental2.png';
import edso from '../../media/img/edso.png';
import enedis from '../../media/img/enedis.png';
import occitanie from '../../media/img/occitanie.png';
import greenTechVerte from '../../media/img/green.png';
import derbi from '../../media/img/derbi2.png';
import tecsol from '../../media/img/tecsol.png';
import logo from '../../media/svg/_LOGO_MOB.svg';
import Footer from './Footer.js'
import Menu from './Menu.js'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { faPlay, faLeaf, faUsers, faCarAlt, faAward, faHandshake, faSun, faStar } from '@fortawesome/free-solid-svg-icons'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// library.add(fab, faBuilding)

class TeamLayout extends Component {

    closeMenu = () => {
        let element = document.getElementById("buttonMenu");
        element.classList.add("collapsed");
        element.setAttribute("aria-expanded", false);
        document.getElementById("collapsingNavbar").classList.remove("show");
    };


    render() {
        return (
            <div className="App">
                <div className="container-fluid">
                    <div className="row">
                        <Menu />
                    </div>
                </div>
                <main>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 bgImage visa desktop">
                            </div>
                            <div className="col-lg-6" id="concept">
                                <div className="half-contentR">
                                    <h2 className="pt-lg-3 pt-lg-2 bigSize">SUNCHAIN, LA GENESE</h2>
                                    <h3 className=" pb-3 ml-5">L’idée d’un homme, la force d’une équipe</h3>
                                    <img className="mobile visaM" src={visa} alt="logo" />
                                    <p>
                                        Depuis toujours, pour le pionnier du solaire André Joffre, la décentralisation de la production d’énergie est la voie de l’avenir, tracée par le numérique.
                                    </p>
                                    <p>
                                        Très tôt, un processus de demande de brevet a été enclenché autour de la répartition des flux d’énergie, en parallèle de discussions approfondies avec Enedis.
                                    </p>
                                    <p >
                                        La dynamique s’est ensuite accélérée pour intégrer la compétence IT alors que la blockchain ne parlait encore qu’au monde de la finance.
                                    </p>
                                    <p>
                                        Puis candidature à l’appel à projets GreenTech Verte du Ministère de la Transition écologique et solidaire, avec succès en juin 2016.
                                    </p>
                                    <p className="pb-5">
                                        L’aventure démarrait.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6" id="techno">
                                <div className="half-contentL">
                                    <h2 className="pt-3">LA VISION / L’AMBITION</h2>
                                    <h3 className=" pb-3 ml-5">Pour des Communautés locales d’énergie renouvelable</h3>
                                    <img className="mobile townM" src={town} alt="schema" />
                                    <p>
                                        De nouveaux schémas d’utilisation des énergies renouvelables apparaissent. Le réseau électrique du futur sera décentralisé, décarboné et digitalisé. Les circuits courts d’énergie seront privilégiés.
                                        Sunchain concrétise cette transformation en proposant aux porteurs de projets innovants de nouveaux modes de valorisation des énergies renouvelables locales.
                                    </p>
                                    <p >
                                        Ainsi, au-delà du concept d’autoconsommation collective, Sunchain est votre allié pour :     </p>
                                    <ul>
                                        <li>Tout service premium de certification d’origine de l’énergie et de traçabilité du kWh</li>
                                        <li>Tout service innovant d’électromobilité solaire : favoriser une recharge verte à la borne</li>
                                        <li>Plus généralement préparer l’avenir de l’énergie digitale !</li>
                                    </ul>


                                </div>
                            </div>
                            <div className="col-lg-6 bgImage town desktop">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 bgImage roof desktop">
                            </div>
                            <div className="col-lg-6" id="applications">
                                <div className="half-contentR">
                                    <h2 className="pt-3">LA TECHNO ET LES APPLICATIONS</h2>
                                    <h3 className="ml-5">Une blockchain spécifiquement conçue pour l’énergie</h3>
                                    <img className="mobile townM" src={roof} alt="schema" />
                                    <ul>
                                        <li>Sobriété énergétique</li>
                                        <li>Pas de cryptomonnaie</li>
                                        <li>Cryptographie asymétrique pour la traçabilité des échanges</li>
                                        <li>Résistance à la fraude par un système de nœuds distribués</li>
                                        <li>Pérennité de la plateforme open-source HyperLedger</li>
                                        <li>Architecture Internet of Things ad hoc</li>
                                    </ul>
                                    <p className="pb-5">
                                        Sunchain est capable d’évaluer rapidement la plus-value du recours à la blockchain et d’adapter le choix technologique pour chaque projet.
                                    </p>
                                </div>
                            </div>


                        </div>
                        <div className="row" id="histoire">
                            <div className="col-xl-12 mb-4">
                                <h2>NOTRE EQUIPE<br /></h2>
                            </div>
                        </div>

                        <div className="row m-5" id="team">
                            <div className="col-lg-12">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 ">

                                        </div>


                                        <div className=" col-lg-2 col-md-4 offset-lg-3 pro01">
                                            <div className="info">
                                                <h4 className="mt-2">
                                                    <a href="https://www.linkedin.com/in/andre-joffre-88850645/" target="_blank" rel="noopener noreferrer">ANDRE JOFFRE<br />
                                                        <span>Co-Fondateur</span>
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 pro02">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    <a href="https://www.linkedin.com/in/christophe-courtois-b8192235/" target="_blank" rel="noopener noreferrer">CHRISTOPHE COURTOIS  <br />
                                                        <span>Président / Co-fondateur</span>
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 pro03">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    <a href="https://www.linkedin.com/in/carolineplaza/" target="_blank" rel="noopener noreferrer">
                                                        CAROLINE PLAZA  <br />
                                                        <span>Co-fondatrice</span>
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 pro05">
                                            <div className="info position-absolute">
                                                <h4 className="mt-2">
                                                    <a href="https://www.linkedin.com/in/julien-cappiello-9ba6661/" target="_blank" rel="noopener noreferrer">
                                                        JULIEN CAPPIELLO  <br />
                                                        <span>Responsable technique</span>
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-4 ">

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className="row" id="histoire">
                            <div className="col-xl-12 mb-4">
                                <h2>NOTRE HISTOIRE<br /></h2>
                            </div>
                        </div>
                        <div className="row" id="applications   ">
                            <VerticalTimeline>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#fcd248', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #fcd248' }}
                                    date="Mai 2016"
                                    iconStyle={{ background: '#fcd248', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faPlay} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Spin-off du bureau d’études solaires <a href="http://tecsol.fr" target="_blank" rel="noopener noreferrer">Tecsol</a></h3>
                                    <h4 className="vertical-timeline-element-subtitle"> > Confortés par 35 ans d’expérience .</h4>
                                    <div align="center">
                                        <br />
                                        <a href="http://tecsol.fr">
                                            <img src={tecsol} alt="logo" height="100" align="center" />
                                        </a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#659c73', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #659c73' }}
                                    date="Septembre 2016"
                                    iconStyle={{ background: '#659c73', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faLeaf} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Lauréat de l’initiative <strong><a href="https://greentechverte.fr" target="_blank" rel="noopener noreferrer"> GreenTech Verte</a></strong></h3>
                                    <h4 className="vertical-timeline-element-subtitle">du ministère de la TES</h4>
                                    <div align="center">
                                        <br />
                                        <a href="https://greentechverte.fr" target="_blank" rel="noopener noreferrer">
                                            <img src={greenTechVerte} alt="logo" height="100" align="center" />
                                        </a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(255, 255, 255)', color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                                    date="Décembre 2016"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faStar} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Membre du <a href="http://www.pole-derbi.com" target="_blank" rel="noopener noreferrer">pôle de compétitivité DERBI</a></h3>
                                    <h4 className="vertical-timeline-element-subtitle">en faveur des ENR</h4>
                                    <div align="center">
                                        <br />
                                        <a href="http://www.pole-derbi.com" target="_blank" rel="noopener noreferrer">
                                            <img src={derbi} alt="logo" height="100" align="center" />
                                        </a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#fff', color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                                    date="Avril 2017"
                                    iconStyle={{ background: '#8ccd2f', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faUsers} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Première entreprise</h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle"> </h4> */}
                                        à avoir signé un partenariat avec <strong><a href="https://www.enedis.fr" target="_blank" rel="noopener noreferrer">Enedis</a></strong> pour l’autoconsommation collective
                                        <div align="center">
                                        <br /><a href="https://www.enedis.fr" target="_blank" rel="noopener noreferrer"><img src={enedis} alt="logo" height="100" align="center" /></a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ background: '#ffa500', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #ffa500' }}
                                    date="Février 2018"
                                    iconStyle={{ background: '#ffa500', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faCarAlt} size="2x" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Lauréat du prix de l’innovation <strong><a href="http://www.plein-soleil.info/actualites/sunchain-laureat-de-la-premiere-edition-du-continental-startup-challenge/" target="_blank" rel="noopener noreferrer">Continental Automotive</a></strong></h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle">Online Course</h4> */}

                                    <div align="center">
                                        <br />
                                        <a href="http://www.plein-soleil.info/actualites/sunchain-laureat-de-la-premiere-edition-du-continental-startup-challenge/" target="_blank" rel="noopener noreferrer"><img src={continental} alt="logo" height="100" align="center" /></a>
                                    </div>

                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ background: '#fff', color: '#000' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                                    date="Mai 2018"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faAward} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Récompense par le Power Networks Europe Award </h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle">Certification</h4> */}
                                        Par l'association des gestionnaires de réseaux européens <a href="https://www.edsoforsmartgrids.eu/entso-e-and-edso-for-smart-grids-launch-the-power-networks-europe-award/" target="_blank" rel="noopener noreferrer">EDSO.</a>
                                    <div align="center">
                                        <br />
                                        <a href="https://www.edsoforsmartgrids.eu/entso-e-and-edso-for-smart-grids-launch-the-power-networks-europe-award/" target="_blank" rel="noopener noreferrer"><img src={edso} alt="logo" height="100" align="center" /></a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ background: '#999', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #999' }}
                                    date="Janvier 2019"
                                    iconStyle={{ background: '#999', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faHandshake} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Accompagné dans nos innovations par <strong><a href="https://www.bpifrance.fr/" target="_blank" rel="noopener noreferrer">BPI France</a></strong>.</h3>
                                    <div align="center">
                                        <a href="https://www.bpifrance.fr/" target="_blank" rel="noopener noreferrer"><img src={bpifrance} alt="logo" width="200" align="center" className="ml-4" /></a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ background: '#ab080e', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #ab080e' }}
                                    date="Janvier 2020"
                                    iconStyle={{ background: '#ab080e', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faHandshake} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Développement soutenu par la <strong><a href="https://www.laregion.fr" target="_blank" rel="noopener noreferrer">région Occitanie</a></strong></h3>
                                    {/* <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4> */}
                                    <div align="center">
                                        <br /><a href="https://www.laregion.fr/" target="_blank" rel="noopener noreferrer"><img src={occitanie} alt="logo" height="100" align="center" /></a>
                                    </div>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--education"
                                    contentStyle={{ background: '#0d293c', color: '#fff' }}
                                    date="Janvier 2021"
                                    contentArrowStyle={{ borderRight: '7px solid  #0d293c' }}
                                    iconStyle={{ background: '#0d293c', color: '#fff' }}
                                    icon={<FontAwesomeIcon icon={faSun} size="lg" />}
                                >
                                    <h3 className="vertical-timeline-element-title">Sunchain se lance dans l'éléctromobilité</h3>
                                    <h4 className="vertical-timeline-element-subtitle"><a href="https://www.enerplan.asso.fr/medias/publication/190912-Convergence-PV-et-mobilite-electrique-V-finale-11.pdf" target="_blank" rel="noopener noreferrer">#Mobelsol</a></h4>
                                    <div align="center">
                                        <br /><img src={logo} alt="logo" height="100" align="center" />
                                    </div>
                                </VerticalTimelineElement>
                            </VerticalTimeline>
                        </div>
                    </div>
                </main >
                <Footer />
            </div >
        )//return
    }

}

export default TeamLayout;