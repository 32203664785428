import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import browserHistory from "react-dom";
import HomeLayout from "./layout/fr/HomeLayout";
import TeamLayout from "./layout/fr/TeamLayout";
import HomeLayoutEn from "./layout/HomeLayoutEn";
const supportsHistory = "pushState" in window.history;
export default class App extends Component {
    render() {
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL} forceRefresh={!supportsHistory}>
                <div className="main">
                    <Switch>
                        <Route history={browserHistory} exact path="/">
                            <HomeLayout />
                        </Route>
                        <Route history={browserHistory} exact path="/sunchain">
                            <TeamLayout />
                        </Route>
                        <Route history={browserHistory} path="/en">
                            <HomeLayoutEn />
                        </Route>
                        
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}
