import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logoNormal from '../../media/svg/_LOGO_NORMAL.svg';
import logoNormalMobile from '../../media/svg/_LOGO_MOB.svg';
import menuButton from '../../media/svg/menu-button.svg';



class MenuLayout extends Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg justify-content-between">
                    <a href="/" className="navbar-brand mr-0 mobile">
                        <img src={logoNormalMobile} alt="logo" className="logo1m logoNavM mr-3" />
                        <img src={logoNormal} alt="logo" className="logo1 logoNavD" />
                    </a>

                    <button id="buttonMenu" className="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar" onClick={() => {
                        this.setState({ close: 'false' });
                    }}>
                        <img src={menuButton} alt="logo" id="logoHambuger" />
                    </button>


                    <div className="navbar-collapse collapse align-items-center mr-xl-5" id="collapsingNavbar">
                        <ul className="navbar-nav mx-auto text-center">

                            <li className="nav-item desktop mt-2  mt-lg-0">
                                <a href="/" className="navbar-brand pt-0 mr-5">
                                    <img src={logoNormalMobile} alt="logo" className="logo1m logoNavM" />
                                    <img src={logoNormal} alt="logo" className="logo1 logoNavD" />
                                </a>
                            </li>

                            <li className="nav-item mt-2 mt-lg-0 mr-xl-3" onClick={this.closeMenu}>
                                <Link to="/#concept">
                                    <span className="nav-link " onClick={this.closeMenu}>AUTOCONSO</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                <Link to="/#autoconso">
                                    <span className="nav-link " onClick={this.closeMenu}>SOLUTION</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                <Link to="/#projets">
                                    <span className="nav-link " onClick={this.closeMenu}>PROJETS</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                <Link to="/#application">
                                    <span className="nav-link" onClick={this.closeMenu}> POUR QUI</span>
                                </Link>
                            </li>
                            <li className="nav-item mt-2 mt-lg-0 mr-xl-3">
                                <Link to="/sunchain#concept">
                                    <span className="nav-link" onClick={this.closeMenu}> QUI SOMMES-NOUS </span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                        <li className="nav-item d-lg-inline-block">
                                <a className="nav-link  btn btn-lg connect recruit mt-3 mr-3" href="https://sunchain.welcomekit.co/">
                                ON RECRUTE <span role="img" aria-label="spaceship">🚀</span>
                                </a>
                            </li>
                            <li className="nav-item d-lg-inline-block">
                                <a className="nav-link  btn btn-lg connect mt-3" href="https://app.sunchain.io">
                                    CONNEXION{/* <i className="fa fa-user pl-2"></i> */}
                                </a>
                            </li>
                            {/*
                                    <li className="nav-item d-lg-inline-block mt-2 mt-lg-0 ml-3">
                                        <Link to="/en">
                                            FR | <span className="grey">EN</span>
                                        </Link>
                                    </li>
                                    */}

                        </ul>

                    </div>
                </nav>

            </div >
        )//return
    }
}

export default MenuLayout;