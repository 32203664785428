import React, { Component } from 'react';
import logo from '../../media/svg/_LOGO_CONTOUR_BLANC.svg';
import schema from '../../media/img/smart-grid.jpg';
import linterface from '../../media/img/InterfaceSC.png';
import premian from '../../media/img/premian.jpg';
import soliha from '../../media/img/soliha2.jpg';
import digisol from '../../media/img/ADEME.png';
import giscours from '../../media/img/giscours.jpg';
import logo_region from '../../media/svg/Logo_Occitanie_2017.svg';
// import profil1 from '../../media/img/profil1.jpg'
// import profil2 from '../../media/img/profil2.jpg'
// import profil3 from '../../media/img/profil3.jpg'
import pdfPremian from '../../media/pdf/20181029_Fiche_référence_Sunchain_Projet_Prémian.pdf'
import pdfDigisol from '../../media/pdf/20190301_Fiche_Lauréat_Digisol.pdf'
import pdfSoliha from '../../media/pdf/20191008_Fiche_réf_Soliha_Résidence_Severac.pdf'
// import pdfGiscours from '../../media/pdf/20191008_Fiche_réf_Soliha_Résidence_Severac.pdf'
import Menu from './Menu.js'
import Footer from './Footer.js'
import Example from './Example.js'
import Modal from './Modal.js'
import Mentions from "../../components/Mentions";

const example1 = {
    title: "Pour un bailleur social",
    icon: "building",
    text: 'Couvrir un maximum des besoins d’électricité du territoire par le solaire et gagner ainsi en autonomie énergétique',
};
const example2 = {
    title: "Pour une collectivité locale",
    icon: "users",
    text: 'Réduire les factures impayées de ses locataires et augmenter leur reste à vivre',
};
const example3 = {
    title: "Pour une zone d’activités",
    icon: "rocket",
    text: 'Apporter un service innovant aux adhérents de l’association économique locale',
};
const example4 = {
    title: "A l’échelle d’un quartier",
    icon: "city",
    text: 'Bénéficier du foisonnement des consommations pour maximiser les économies de chaque participant',
};

const modalPremian = {
    title: "Prémian Energie Positive (34)",
    modalName: "Premian",
    pdf: pdfPremian,
};

const modalSoliha = {
    title: "SoliHa, Onet-le-Château (12)",
    modalName: "Soliha",
    pdf: pdfSoliha,
};

const modalGiscours = {
    title: "Giscours",
    modalName: "Giscours",
    pdf: pdfDigisol,
};
const modalDigisol = {
    title: "Programme Digisol",
    modalName: "Digisol",
    pdf: pdfDigisol,
};


class HomeLayout extends Component {

    closeMenu = (link) => {
        let element = document.getElementById("buttonMenu");
        element.classList.add("collapsed");
        element.setAttribute("aria-expanded", false);
        document.getElementById("collapsingNavbar").classList.remove("show");
    };

    render() {
        return (
            <div className="App">
                <Menu />
                <header>
                    <div className="container-fluid">
                        <div className="row" id="topSite">
                            <div className="col-12">
                                <div className="page-header">
                                    <div className="">
                                        <img src={logo} alt="logo" id="logo2" />
                                        <div className="clear" />
                                        <span>
                                            L’énergie est notre avenir,
                                            <br />
                                            <b>partageons-la !</b>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 desktop mt-2 resize">
                                <div className="videoWrapper mt-5 pt-5">
                                    <iframe
                                        src="https://player.vimeo.com/video/207315547"
                                        width="560"
                                        height="349"
                                        frameBorder="0"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowFullScreen
                                        title="Video Sunchain"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6" id="concept">
                                <div className="half-contentR">
                                    <h2 className="pt-lg-3 pt-lg-2 bigSize">L’AUTOCONSO COLLECTIVE</h2>
                                    <h3 className=" pb-3 ml-5">Mutualiser et partager la production solaire</h3>
                                    <div className="mobile video">
                                        <iframe
                                            src="https://player.vimeo.com/video/207315547"
                                            width="100%"
                                            height="100%"
                                            frameBorder="0"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            allowFullScreen
                                            title="Video Sunchain"
                                        />
                                    </div>
                                    <p>
                                        De plus en plus, on veut connaître et choisir l’origine de ce que l’on mange, de ce que l’on consomme. L’énergie n’échappe pas à cette tendance profonde.
                                        <br /><br />L’autoconsommation collective d’électricité consiste à partager l’énergie produite par une source renouvelable locale.
                                    </p>
                                    <p>
                                        « L'opération d'autoconsommation est collective lorsque la fourniture d'électricité est effectuée entre un ou plusieurs producteurs et un ou plusieurs consommateurs (…) »
                                        Article L.315-2 du Code de l’énergie
                                    </p>
                                    <p className="pb-5">
                                        L’union fait la force : par la complémentarité des profils de consommation, on augmente la part de la production consommée ici et maintenant.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-6" id="autoconso">
                                <div className="half-contentL">
                                    <h2 className="pt-lg-3">LA SOLUTION SUNCHAIN</h2>
                                    <h3 className=" pb-3 ml-5">Optimiser votre autoconsommation collective</h3>
                                    <img className="mobile townM" src={linterface} alt="logo" />
                                    <p>
                                        Sur le plan économique :
                                    </p>

                                    <ul>
                                        <li>maximiser le gain de l’opération pour tous les participants, grâce à une répartition dynamique personnalisable ;</li>
                                        <li>disposer de données énergétiques quotidiennes et de bilans économiques mensuels.</li>
                                    </ul>

                                    <p>
                                        Sur le plan organisationnel :
                                    </p>
                                    <ul>
                                        <li>créer et maintenir la confiance entre des participants qui ne se connaissent pas forcément ;</li>
                                        <li>permettre à chacun de suivre sa consommation au fil du soleil, et encourager celle-ci ;</li>
                                        <li>se reposer sur un tiers pour gérer la relation chronophage avec le gestionnaire du réseau public de distribution d’électricité.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 bgImage linterface desktop">
                            </div>
                        </div>
                        <div className="row pb-4" id="projets">
                            <div className="col-md-12 col-center m-auto" >
                                <h2>LES PROJETS ACTUELS</h2>
                            </div>
                        </div>

                        <div className="row" id="clients">
                            <div className="col-lg-3 col-md-6 ">
                                <div className="card mt-5" >
                                    <img className="card-img-top" src={premian} alt="Autoconsommation Collective de Premian" />
                                    <div className="card-body">
                                        <h4 className="card-title">Prémian Energie Positive (34)</h4>
                                        <p className="card-text">Le premier projet de Sunchain, dont la solution permet à 6 bâtiments du coeur de village d'être alimentés par les 28 kWc de la toiture photovoltaïque de l'atelier technique municipal.</p>
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#fichePremian">
                                            Détails
                                        </button>
                                        {/* <a href="#" className="btn btn-primary">Détails</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 ">
                                <div className="card mt-5 mb-5" >
                                    <img className="card-img-top" src={soliha} alt="Autoconsommation Collective de Soliha" />
                                    <div className="card-body">
                                        <h4 className="card-title">SoliHa, Onet-le-Château (12)</h4>
                                        <p className="card-text">Un projet pionnier par la proximité du bailleur social avec ses locataires et leur accompagnement dans les économies d'énergie. Les 20 foyers profitent gratuitement de l'énergie produite sur leur toit.</p>
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ficheSoliha">
                                            Détails
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card mt-5 mb-5" >
                                    <img className="card-img-top" src={giscours} alt="Giscours, Labarde (33)" />
                                    <div className="card-body">
                                        <h4 className="card-title">Giscours, Labarde (33)</h4>
                                        <p className="card-text">Première pour un grand domaine du Bordelais : un hangar viticole équipé d'une toiture solaire de 80 kWc dont la production est partagée entre les bâtiments alentours.</p>
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ficheGiscours">
                                            Détails
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="card mt-5 mb-5" align="center" >
                                    <img className="card-img-top ademe" src={digisol} alt="Programme DIGISOL" />
                                    <div className="card-body">
                                        <h4 className="card-title">Programme DIGISOL</h4>
                                        <p className="card-text">Une solution "sur-mesure" est déployée pour optimiser les opérations de chaque client. De nombreux projets sont en cours dans le cadre de DIGISOL, soutenu par le Programme d’Investissements d’Avenir opéré par l’ADEME.</p>
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ficheDigisol">
                                            Détails
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row pb-4" id="concept">
                            <div className="col-md-12 col-center m-auto" >
                                <h2>QU'EN DISENT NOS CLIENTS?</h2>
                            </div>
                        </div>
                        <div className="row" id="testimonial">
                            <div className="col-md-12 col-lg-10  col-center m-auto" >

                                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                    {/* <!-- Carousel indicators --> */}
                                    <ol className="carousel-indicators">
                                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                        <li data-target="#myCarousel" data-slide-to="1"></li>
                                        {/* <li data-target="#myCarousel" data-slide-to="2"></li> */}
                                    </ol>
                                    {/* <!-- Wrapper for carousel items --> */}
                                    <div className="carousel-inner">
                                        <div className="item carousel-item active">
                                            {/* <div className="img-box"><img src={profil1} alt="" /></div> */}
                                            <p className="testimonial">« Nous sommes très fiers de cette opération porteuse de sens. Nous souhaitons faire adhérer l’ensemble de la population de Prémian au principe de l’autoconsommation collective. »</p>
                                            <p className="overview"><b>Roland Coutou</b>, Maire adjoint de Prémian</p>
                                        </div>
                                        <div className="item carousel-item">
                                            {/* <div className="img-box"><img src={profil2} alt="" /></div> */}
                                            <p className="testimonial">« Soliha Aveyron souhaitait profiter des travaux de rénovation pour verdir l’immeuble de manière à inclure les locataires, sur le principe du jardin partagé. Grâce à Sunchain, c’est maintenant possible et chacun s’y retrouve. »</p>
                                            <p className="overview"><b>Luc Soulié</b>, Conducteur de travaux SoliHa</p>
                                        </div>
                                        {/* <div className="item carousel-item">
                                            <div className="img-box"><img src={profil3} alt="" /></div>
                                            <p className="testimonial">Phasellus vitae suscipit justo. Mauris pharetra feugiat ante id lacinia. Etiam faucibus mauris id tempor egestas. Duis luctus turpis at accumsan tincidunt. Phasellus risus risus, volutpat vel tellus ac, tincidunt fringilla massa. Etiam hendrerit dolor eget rutrum.</p>
                                            <p className="overview"><b>Michael Holz</b>, Seo Analyst</p>
                                        </div> */}
                                    </div>
                                    {/* <!-- Carousel controls --> */}
                                    <a className="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                    <a className="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <hr className="testimonial_separator" />
                        <div>
                            <div className="row">
                                <div className="col-lg-6" id="application">
                                    <div className="half-contentL">
                                        <h2 className="pt-3">POUR QUI</h2>
                                        <h3 className=" pb-3 ml-5">Les cas d'usage de l'autoconso collective</h3>
                                        <img className="mobile  townM" src={schema} alt="schema" />
                                        <h4 className="pt-3">Consommateurs :</h4>
                                        <p>
                                            • être acteur de sa consommation d’énergie, sans la contrainte d’installer des panneaux solaires sur sa toiture ;
                                        </p>
                                        <p>
                                            • réduire sa facture énergétique.
                                        </p>
                                        <h4 className="pt-3">Producteurs :</h4>
                                        <p>
                                            • choisir l’implantation de l’installation solaire la plus adéquate ;
                                        </p>
                                        <p>
                                            • opter pour une valorisation économique de la prodution qui soit porteuse de sens.
                                        </p><br />

                                    </div>
                                </div>

                                <div className="col-lg-6 bgImage schema desktop">
                                </div>
                            </div>

                            <div className="row" id="concept">
                                    <div className="col-xl-3 p-5">
                                        <Example title={example1.title} icon={example1.icon} text={example1.text} />
                                    </div>
                                    <div className="col-xl-3 p-5">
                                        <Example title={example2.title} icon={example2.icon} text={example2.text} />
                                    </div>
                                    <div className="col-xl-3 p-5">
                                        <Example title={example3.title} icon={example3.icon} text={example3.text} />
                                    </div>
                                    <div className="col-xl-3 p-5">
                                        <Example title={example4.title} icon={example4.icon} text={example4.text} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div align="center" id="region">
                        
                        <img 
                        className="region" 
                        src={logo_region} 
                        alt="logo region occitanie" 
                        id="logo_region" />
                        
                    </div>
                </main >

                <Footer />

                {/* // Modals  */}
                <Modal title={modalPremian.title} modalName={modalPremian.modalName} pdf={modalPremian.pdf} />
                <Modal title={modalSoliha.title} modalName={modalSoliha.modalName} pdf={modalSoliha.pdf} />
                <Modal title={modalDigisol.title} modalName={modalDigisol.modalName} pdf={modalDigisol.pdf} />
                <Modal title={modalGiscours.title} modalName={modalGiscours.modalName} pdf={modalGiscours.pdf} />
                <div className="container-fluid endDeskMobile">
                    <div>
                        <div className="infocopyright">
                            Copyright 2021&#160;&#160;&#160;|
                                    {/* Button trigger modal */}
                            <button type="button" className="btn" data-toggle="modal" data-target="#modalMobile">
                                Mentions Légales
                            </button>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="modalMobile" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title" id="exampleModalLongTitle">Mentions Légales</h1>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Mentions />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        )//return
    }

}

export default HomeLayout;