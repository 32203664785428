import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab, faAngellist, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import { } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// library.add(fab, faAngellist, faTwitter, faLinkedin)

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="page-footer font-small mdb-color pt-4" id="contact">
                    <div className="desktop">
                        <div className="container text-center text-md-left">
                            <div className="row text-center text-md-left mt-3 pb-3">

                                <div className="col-lg-3 col-xl-4 mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">SUNCHAIN</h6>
                                    <div className=" mt-2">
                                        <Link to="/#concept">Autoconso</Link>
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/#autoconso">Solution</Link>
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/#projets">Projets</Link>
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/#application">Pour qui?</Link>
                                    </div>
                                    <hr className="w-100 clearfix d-md-none" />
                                </div>
                                <hr className="w-100 clearfix d-md-none" />
                                <div className=" col-lg-3 col-xl-4 mx-auto mt-3"> {/* Qui sommes nous */}
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Qui sommes nous</h6>
                                    <div className=" mt-2">
                                        <Link to="/sunchain#concept">La genèse</Link>
                                        {/* <a className="" href="/sunchain#concept"></a> */}
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/sunchain#techno">La vision</Link>
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/sunchain#applications">La techno</Link>
                                    </div>
                                    <div className=" mt-2">
                                        <Link to="/sunchain#team">L'équipe</Link>
                                    </div>
                                </div>
                                <hr className="w-100 clearfix d-md-none" />
                                <div className="col-lg-5 col-xl-4 mx-auto mt-3 text-left"> {/* Contact */}
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                                    <p className="pt-0">
                                        <i className="fa fa-home"></i>105 avenue Alfred Kastler - BP51008<br />
                                        <span className="pad">66101 Perpignan Cedex - France</span></p>
                                    <p>
                                        <i className="fa fa-envelope"></i> <a href="mailto:info@sunchain.fr">info@sunchain.fr</a></p>
                                    <p>
                                        <i className="fa fa-phone"></i>+33 (0)4 68 68 16 58</p>
                                    <div>
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/SunChain_fr" className="ml-2">
                                            <i className="fa fa-twitter"></i>
                                        </a>

                                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/sunchain">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://angel.co/company/sunchain">
                                            <i className="fa fa-angellist"></i>
                                        </a>
                                    </div>
                                </div>

                                <hr />

                            </div>
                        </div>
                    </div>
                    <div className="mobile">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <div className="pb-4">
                                        <a className="text-left" href="https://angel.co/company/sunchain">
                                            On recrute<img src="https://s.w.org/images/core/emoji/11/svg/1f680.svg" alt="emoji" className="emoji" draggable="false" />
                                        </a>
                                    </div> */}
                                    <p>
                                        105 Av. Alfred Kastler - BP51008<br />
                                        66001 Perpignan - France<br /><br />
                                    </p>

                                    <a href="mailto:info@sunchain.fr">info@sunchain.fr</a>
                                    <br /><br />
                                    +33 (0)4 68 68 16 58
                                    <br /><br />

                                    <div className="s12 pt10">
                                        <span className="pr10">Suivez-nous : &nbsp;
                                            <a href="https://twitter.com/SunChain_fr">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </span>
                                        <a href="https://www.linkedin.com/company/sunchain">
                                            <i className="fa fa-linkedin"></i> */}
                                        </a>
                                        {/* <a href="https://www.linkedin.com/company/sunchain">
                                            <FontAwesomeIcon icon="linkedin" />
                                        </a> */}
                                        <br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="container-fluid endDeskMobile"></div>
            </div >
        )//return
    }
}

export default Footer;